import { graphql } from 'gatsby';
import React from 'react';

// Components
import { FilterBar } from 'components';

// Layouts
import { SEOContent } from 'layouts';

// Templates
import { BalanceSummary, BasicTemplate } from 'templates';

// Types
import { OrganizationSummary } from 'src/types';
interface Props {
  data: {
    organizations: OrganizationSummary;
  };
}

const FILTERS = [{ title: 'Organization', gqlKey: 'name' }];

const title = 'Billing Summary';

const BillingSummaryPageTemplate = (props: Props) => {
  const { organizations } = props.data;

  return (
    <SEOContent>
      <BasicTemplate templateTitle={title}>
        <section>
          <FilterBar
            data={organizations}
            filters={FILTERS}
            render={tableData => <BalanceSummary data={tableData} />}
          />
        </section>
      </BasicTemplate>
    </SEOContent>
  );
};

export default BillingSummaryPageTemplate;

export const query = graphql`
  query {
    organizations: allAirtable(
      filter: { table: { eq: "Billing Summary Page" } }
    ) {
      nodes {
        data {
          balance
        }
      }
    }
  }
`;
